.head {
  background: #eac830;
  height: 50px;
  display: flex;
  align-items: center;
}
.head-container {
  width: 95%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.location {
  width: 42%;
  display: flex;
  align-items: center;
  /* border: 1px solid white; */
  gap: 10px;
}
.conta-header{
  color: #04476e;
  text-decoration: none;
}
.location p {
  font-size: 16px;
  color: #04476e;
  font-weight: 500;
}
.right-sec {
  width: 31%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.loc-div{
  margin-top: 7px;
  font-size: 23px;
  color: #dc4c4f;
}
.loc-div1{
  display: flex
  ;
      width: 30px;
      height: 31px;
      font-size: 21px;
      color: #ffff;
      background-color: #dc4c4f;
      border-radius: 2px;
      justify-content: center;
      align-items: center;
}
.right-sec p {
  font-size: 16px;
  color: #cb3b42;
  font-weight: 500;
}
.icon-container {
  cursor: pointer;
}
.icon-container a {
  text-decoration: none;
  color: white;
}
.whatsapp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed; /* Change from sticky to fixed for bottom positioning */
  bottom: 20px; /* Distance from the bottom of the screen */
  right: 20px; /* Distance from the right side of the screen */
  background-color: #25d366; /* WhatsApp green background */
  border-radius: 20%;
  width: 40px;
  height: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  z-index: 1000; /* Ensure it stays above other elements */
  cursor: pointer; /* Show pointer cursor on hover */
}
@media (max-width:768px){

  .location{
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid white; */
    gap: 10px;
    justify-content: center;
    margin-bottom: 10px;
  }
.head-container{
  display: none;
}
.location p {
  font-size: 14px;
  color: black;
  font-weight: 500;
}
.head{
  display: none;
}
}
.whatsapp-container > i {
  color: white; /* Icon color */
  font-size: 30px; /* Icon size */
}
