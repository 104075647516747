/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navbar Section */
.navbar4 {
  display: flex;
  justify-content: space-evenly;
  /* height: 65px; */
  padding: 10px 10px;
  width: 90%;
  margin: auto;
  gap: 60px;
}

.navbar-second {
  display: block;
  width: 52%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-detail {
  display: flex;
  align-items: center;
 width: 53%;
  padding: 10px 0px;
  /* width: 100% !important; */
  font-family: "Archivo", Sans-serif;
  justify-content: center;
}
.call {
  display: flex;
  column-gap: 5px;
  border-width: 1px;
  line-height: 1.2;
  border-right: 1px solid;
  font-weight: 600;
  font-size: 15px;
  padding-right: 10px;
  color: #04476e;
  cursor: pointer;
}
.number {
  font-weight: 500;
  font-size: 15px;
  color: #04476e;
}

.contact {
  border: none;
  padding-left: 10px;
  font-weight: 600;
  font-size: 15px;
  color: #1b1b1b;
  cursor: pointer;
}
.call-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.fa-phone {
  width: 27px;
  height: 29px;
  display: flex;
  align-items: center;
  background-color: #cb3b42;
  justify-content: center;
  border-radius: 4px;
  color: white;
  font-size: 15px;
  margin-top: -13px;
}
.link-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 20px;
}
.link-container p {
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 12px;
  border-right: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-container :last-child p {
  border: none;
}
.contact-link {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  font-family: sans-serif;
}
.navbar-first {
  width: 40%;
  height: 112px;
  display: flex;
  justify-content: center;
}
.bosh-navbar{
color: #e71e24;
font-weight: 600;
text-align: center;
font-size: 24px;
}
.second-logo {
  display: flex;
  height: 130px;
  justify-content: flex-end;
}
.second-logo img {
  width: 50%;
  height: 50%;
}
.navbar-third-sec {
  width: 121px;
  /* display: flex;
  align-items: start;
  justify-content: center; */
}

/* Default Link Style */
.navbar-second a,
.navbar-third a,
.navbar-third-sec .contact {
  color: black; /* Default color */
  text-decoration: none;
}
.loc-div{
  margin-top: -16px;
}
/* Contact Section */
.navbar-third-sec.contact {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 20px;
  padding: 3px 10px;
}

/* Active Link Style - Fix applied here */
.navbar-second a.active-link,
.navbar-third-sec a.active-link,
.nav-links-mobile a.active-link,
.navbar-third-sec .contact.active-link {
  color: #a00000 !important; /* Active text color */
  border-radius: 5px;
  /* padding: 5px 10px; */
}

/* Active State for Contact Section */
.navbar-third-sec .contact.active-link,
.navbar-third-sec .contact.active-link p,
.navbar-third-sec .contact.active-link .contact1 {
  color: white !important; /* Active color for contact text and icon */
}
.whatsapp-container1{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background-color: #25d366; /* WhatsApp green background */
  border-radius: 20%;
  width: 25px;
  height: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
 /* Ensure it stays above other elements */
  cursor: pointer; /* Show pointer cursor on hover */
}
.whatsapp-container1 > i {
  color: white; /* Icon color */
  font-size: 20px; /* Icon size */
}
/* Additional Active Styles */
.active-link1 {
  background-color: rgb(63, 99, 63);
  color: white; /* Active color */
}

.contact1 {
  color: #094e1f; /* Default icon color */
}

.active-link2 {
  color: white; /* Active icon color */
  margin-right: 2px;
  margin-top: 3px;
}

/* Enquiry Button */
.enquiry-button {
  font-size: 14px;
  width: 100%;
  /* border: 1px solid #094e1f; */
  outline: none;
  border: none;
  color: white;
  font-weight: bold;
  padding: 7px;
  border-radius: 0px;
  text-align: center;
  cursor: pointer;
  background-color: #05456c;
}
.enquiry-button1 {
  width: 100%;
  /* border: 1px solid #094e1f; */
  outline: none;
  border: none;
  color: white;
  font-weight: bold;
  padding: 12px;
  border-radius: 0px;
  text-align: center;
  cursor: pointer;
  background-color: #094e1f;
}
.cont2222 {
  font-size: 15px;
}
.call-us {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.call-us button {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px;
}

/* Navbar Image Styling */
.nav-img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}
.nav-links {
  display: none;
}
.active-link24 {
  color: #fff;
}
.active-link4 {
  color: white;
}
/* Mobile View Styling */
@media (max-width: 768px) {
  .fa-phone {
   margin-top: 0px;
  }
  .second-logo {
    display: flex;
    height: 77px;
    justify-content: center;
  }
  .second-logo img {
    width: 50%;
    height: 100%;
  }
  .bosh-navbar{
    color: #e71e24;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    }
  .fa-phone{
    background-color: #05456c;
  }
  .navbar-third-sec {
    width: 100%;
    /* display: flex;
    align-items: start;
    justify-content: center; */
  }
  .location-link{
    text-decoration: none;
  }
  .navbar1 {
    width: 96%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  .logo {
    width: 70%;
  }

  .logo .nav-img {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }

  .nav-links-mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    height: 140vh;
    background-color: #05456c;
    padding-top: 50px;
    list-style: none;
    text-align: center;
    row-gap: 20px;
    z-index: 100;
  }

  .nav-links-mobile a {
    text-decoration: none;
    color: #ffff;
    font-size: 22px;
  }
  .enquiry-button {
    font-size: 22px;
    color: white;
    background-color: #05456c;
  }
  .enquiry-button1 {
    font-size: 22px;
    color: #ffff;
    background-color: #05456c;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    color: #05456c;
  }
}
