* {
    padding: 0;
    margin: 0;
  }
  
  .contact-container {
    width: 100%;
    margin: auto;
    padding: 20px 0px;
  }
  
  .contact-first-section {
    background-color: #05456c;
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-top: 20px;
  }
  
  .first-div {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #05456c;
    width: 50%;
    padding: 30px 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group > label {
    display: block;
    margin-bottom: 5px;
    color: white;
  }
  
  .input-group > input,
  .input-group > textarea {
    background-color: #05456c;
    border: 1px solid white;
    border-radius: 5px;
    width: 100%;
    padding: 15px; /* Adjusted padding for better look */
    color: white;
  }
  
  .first-div > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: auto;
    padding: 20px 8px;
    border-radius: 10px;
    font-weight: bolder;
    margin-top: 10px;
    cursor: pointer;
    color: #094e1f;
    background-color: white;
    font-size: 20px;
    outline: none;
    border: none;
  }
  
  .input-group > input::placeholder,
  .input-group > textarea::placeholder {
    color: white;
  }
  .upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    width: 100%;
    padding: 15px;
    background-color: white;
    font-size: 20px;
    color: #05456c;
  }
  
  .second-div {
    width: 50%;
    height: 541px;
    border-radius: 10px;
  }
  .map-here {
    height: 100%;
    width: 100%;
    background-color: white;
    /* height: 500px; */
    padding: opx 20px;
    border-radius: 10px;
    border: 1px solid #094e1f;
  }
  .map-here > iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .submitBtn {
    width: 50%;
    display: block;
    margin: auto;
    background-color: white;
    padding: 15px;
    outline: none;
    border: none;
    border-radius: 10px;
    color: #05456c;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .contact-first-section {
      width: 100%;
      flex-direction: column;
      column-gap: 20px;
      margin-top: 20px;
    }
    .map-here {
      border: none;
      height: 100%;
      width: 100%;
      background-color: white;
      height: 300px;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 3px;
    }
    .first-div {
      border-top-right-radius: 0%;
      border-bottom-right-radius: 0%;
      width: 100%;
      margin: auto;
      padding: 30px;
    }
  
    .second-div {
      border-radius: 20px;
      width: 98%;
      margin: auto;
      padding: 20px 0px;
    }
  }