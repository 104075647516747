.serviceContainer {
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }
  .productContainer {
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 10px;
  }
  .whatsapp-container2{
    padding: 10px;
    font-size: 28px;
    color: white;
    margin-top: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25d366;
    border-radius: 20%;
    width: 40px;
    height: 39px;
    cursor: pointer;
  }

 
 
 
  .product {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    padding: 15px;
  }
  .productImage {
    width: 25%;
    height: 279px;
    border-radius: 10px;
  }
  .productImage img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .productContent {
    width: 75%;
  }
  .productContent > h1 {
    font-size: 21px;
    color: #05456c;
  }
  .productContent > p {
    line-height: 22px;
    margin-top: 10px;
  }
  .product-brand {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 10px;
  }
  .brandCont {
    width: 11%;
    height: 50px;
  }
  .brandCont > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .btn {
    margin-top: 30px;
    padding: 10px;
    width: 25%;
    border-radius: 5px;
    outline: none;
    border: 1px solid gray;
    color: green;
    cursor: pointer;
  }
  
  @media (min-width: 300px) and (max-width: 500px) {
    .product {
      flex-direction: column;
    }
    .productImage {
      width: 100%;
    }
    .productContent {
      width: 100%;
    }
    .brandCont {
      width: 18%;
    }
    .btn {
      width: 100%;
    }
  }
  @media (min-width: 501px) and (max-width: 799px) {
    .product {
      flex-direction: column;
    }
    .productImage {
      width: 100%;
    }
    .productContent {
      width: 100%;
    }
    .brandCont {
      width: 18%;
    }
    .btn {
      width: 100%;
    }
  }
  