/* Main container for the contact form section */
.contact-form {
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
  /* padding: 0px 20px; */
  font-family: Arial, sans-serif;
}

/* Section Title */
.contact-form h1 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  /* text-align: center; */
  color: #05456c;
}

/* Main content layout */
.cont-main-content {

  display: flex;
  gap: 30px;
  justify-content: space-between;
  padding: 20px 0px;
  height: 300px;
  background-color: #05456c;
}

/* Left section - Contact information */
.cont-main-content > .cont-main-first {
  width: 85%;
  margin: auto;
  /* flex: 1; */
  padding: 20px 15px 20px 20px;
  color: white;
  border-radius: 8px;
  display: flex;
  /* flex-direction: column; */
  column-gap: 30px;
 
}

.cont-main-content > .cont-main-first> div:first-child{
  width: 50%;
  height: 200px;
}

.cont-main-content h1 {
  font-size: 22px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

.cont-main-content p {
  line-height: 1.6;
  margin: 6px 0;
}
.checkmark{
  font-size: 13px;
}
/* Checkmark styling for list items */
.checkmark::before {
  content: "✔ ";
  color: #ffff;
  margin-right: 5px;
  font-weight: bold;
  font-size: 15px;
}


.contact-form>.second-div{
  display: flex;
  width: 40%;
  /* flex-direction: row; */
  flex-wrap: wrap;
  margin-top: 40px;
  margin-left: 60px;
 justify-content: center;
 align-items: center;
 height: 200px;
} 
.contact-form>.second-div>div{
  object-position: center;
}
.contact-form>.second-div>div>img {
  width: 100%;
  height: 40px;
  max-width: 100px;
  object-fit: contain;
  object-position: center;
}

/* Right section - Contact form */
.second-cont-div {
  width: 60%;
  flex: 1;
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  height: 500px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.second-cont-div h2 {
  font-size: 20px;
  color: #05456c;
  text-align: center;
  margin-bottom: 20px;
}

/* Form elements */
.second-cont-div form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.second-cont-div input,
.second-cont-div select,
.second-cont-div textarea {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.second-cont-div input[type="date"],
.second-cont-div select {
  appearance: none;
  position: relative;
}

.second-cont-div input[type="date"]::before {
  content: "📅";
  position: absolute;
  right: 10px;
  color: #aaa;
}

.second-cont-div input[type="submit"] {
  background-color: #05456c;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.second-cont-div input[type="submit"]:hover {
  background-color: #052e15;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cont-main-content {
    flex-direction: column;
    align-items: center;
  }
  .cont-main-content > .cont-main-first> div:first-child{
    width: 100%;
    height: 200px;
  }
  .second-div{
    margin: 0px;
  }
  .second-cont-div{
    width: 100%;
    margin: auto;
    margin: 0px;
    margin-top: 80px;
  }
  .cont-main-content > div {
    width: 100%;
  }
  .con33-mainf-3{
    width: 100%;
  }
  .cont-main-content > .cont-main-first {
    width: 95%;
    margin: auto;
    /* flex: 1; */
    /* padding: 20px 15px 20px 20px; */
    color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    column-gap: 30px;
   
  }
  .second-div22{
    margin-top: 520px;
  }
}
