.modal-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(198, 196, 196, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content1 {
    background: #05456c;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    border-radius: 8px;
    position: relative;
  }
  
  .close-button1 {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .form11 input,
  .form11 textarea,
  .form11 .upload-button1 {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 8px;
  }
  
  .upload-button1 {
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: none;
    padding: 10px;
    background-color: rgb(181, 174, 174);
  }
  
  @media (max-width: 768px) {
    .modal-content1 {
      width: 95%;
    }
  }
  