/* Carousel Container */
.carousel-container2223 {
  width: 100%;
  height: 400px;
  overflow: hidden; /* Hide the overflowing slides */
  position: relative;
}

/* Wrapper for the slides */
.carousel-slides1123 {
  margin-top: 50px;
  display: flex; /* Align slides in a row */
  height: 100%;
  animation: slideAnimation 23s infinite; /* Apply animation with 35s duration and infinite loop */
}

/* Keyframes for sliding the slides from left to right */
@keyframes slideAnimation {
  0% {
    transform: translateX(0%); /* Start from the first slide */
  }
  12.5% {
    transform: translateX(-700%); /* Move to the second slide */
  }
  25% {
    transform: translateX(-600%); /* Move to the third slide */
  }
  37.5% {
    transform: translateX(-500%); /* Move to the fourth slide */
  }
  50% {
    transform: translateX(-400%); /* Move to the fifth slide */
  }
  62.5% {
    transform: translateX(-300%); /* Move to the sixth slide */
  }
  75% {
    transform: translateX(-200%); /* Move to the seventh slide */
  }
  87.5% {
    transform: translateX(-100%); /* Return to the first slide */
  }
  100% {
    transform: translateX(0%); /* Reset to the first slide */
  }
}

/* Individual Slide */
.carousel-item1123 {
  min-width: 100%; /* Each slide takes full container width */
  display: flex;
  height: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Image Styling */
.carousel-image1123 {
  width: 100%;
  height: 300px;
  object-fit: contain; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: rounded corners */
}

/* Caption Styling */
.carousel-caption1123 {
  text-align: center;
  margin-top: 15px;
}

.carousel-caption1123 h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}
