*{
    padding: 0;
    margin: 0;
}

.aboutus-container{
    width: 100%;
    margin: auto;
}

.aboutus-first-container{
    width: 80%;
    margin: auto;
}
.first-section{
    width: 94%;
    margin: auto;
    text-align: center;  
}
.image33{
    width: 50%;
    display: flex;
    height: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ul-about{
    margin-left:16px;
    margin-top:12px;
}
.fount-text{
    width: 30%;
    
}
.image33>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.first-section>h1{
    font-size: 26px;
    font-weight: 560;
    line-height: 39px;
    text-align: center;
    color: #05456c;
    margin-bottom: 16px;
}

.first-section>p{
    font-size: 12px;
}

.first-first-section{
    width: 100%;
    display: flex;
   margin-top: 40px;
   justify-content: center;
   align-items: center;
   margin-bottom: 40px;
   column-gap: 30px;
   line-height: 1.5;
 
}
.first-first-aboutus{
    width: 100%;
  
}
.description-aboutus{
    border-radius: 20px;
    width: 94%;
    /* height: 100%; */
    padding: 10px 17px;
    background-color: #e4e4e4;
}
.description-aboutus>p{
    font-size: 13px;
}

.imga-aboutus{
 width: 50%;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.imga-aboutus>img{
    width: 100%;
    height: 282px;
    object-fit: contain;
    object-position: top;
   }
.first-h2{
 font-size: 16px;
 color: #05456c;
 margin-bottom: 10px;
 text-align: center;
}

.first-second-section{
   width: 100%;
   display: flex;
   margin: auto;
   justify-content: space-between;
   align-items: center;
  column-gap: 30px;
  line-height: 1.5;
  margin-top: 40px;
  margin-bottom: 40px;
}

.first-second-aboutus{
    border-radius: 20px;
    width: 60%;
    /* height: 100%; */
    padding: 10px 17px;
    background-color: #e0e0e0;
  
}
.first-second-aboutus>div>p{
    font-size: 13px;
}
.imga-aboutus1{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 4px; */
}
.imga-aboutus1>img{
    width: 100%;
    /* height: 282; */
    object-fit: cover;
    /* padding-top: 60px; */
}
.first-h3{
    font-size: 14px;
 color: #05456c;
 text-align: center;
}
.first-third-section{
    margin-top: 30px;
}
.first-third-first{
    width: 100%;
    text-align: center;
}

.first-third-second{
    margin-top: 20px;
}


.first-div1{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    /* background-color: #094e1f; */
    width: 70%;
    margin: auto;
    padding:30px 20px;
}
.contact-container1{
    background-color: #094e1f;  
    width: 100%;
}
.founder-section{
    width: 80%;
    margin: auto;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.first-first-aboutus1{
    color: white;
    width: 20%;

}
.aboutus-border{
    width: 100%;
    border: 1px solid black;
    margin-bottom: 20px;
}
.first-div1 > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: auto;
    padding: 20px 8px;
    border-radius: 10px;
    font-weight: bolder;
    margin-top: 10px;
    cursor: pointer;
    color: #05456c;
    background-color: white;
}
.welcome-image12{
    width: 50%;
    padding-top: 7px;
}
.welcome-image12>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.mobile-test1{
    width: 100%;
    margin: auto;
}
.mobile-test1>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width:768px){
    .welcome-image12 {
   width: 100%;
    }
    .fount-text {
        margin-top: 20px;
        text-align: center;
        width: 74%;
    }
    .founder-section{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .first-first-aboutus1{
        text-align: center;
        width: 84%;
    }
    .image33{
        width: 90%;
    }
    .description-aboutus{
        border-radius: 20px;
        width: 100%;
        height: 100%;
        padding: 10px 17px;
        background-color: #e4e4e4;
    }
    .first-div1{
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        /* background-color: #094e1f; */
        width: 80%;
        margin: auto;
        padding:30px 20px;
    }
    .first-first-section{
        display: flex;
        flex-direction: column;
    }
    .imga-aboutus{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       }
    .first-second-section{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .imga-aboutus1{
        width: 100%;
        height: 222px;
    }
    .first-first-section{
       height: auto; 
       row-gap: 30px;
   }
 
   .imga-aboutus>img{
    width: 100%;
    height: 222px;
   }
   .first-second-aboutus{
    width: 100%;
    margin-top: 20px;
   }
   .mobile-testmonial{
    display:flex;
    flex-direction:column;
    row-gap:15px;
   }
   .mobile-testmonial>div{
    width: 100%;
    height: 300px;
   }
   .mobile-testmonial>div>img{
    width: 100%;
     height: 100%;
     object-fit: cover;
   }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .first-second-section {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 10px;
    }
    .mobile-testmonial{
        display:grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: auto;
        row-gap:15px;
       }
    .mobile-testmonial>div>img{
        width: 100%;
         height: 100%;
         object-fit: contain;
       }
}