#faq {
    width: 100%;
    padding: 10px;
    margin-top: 50px;
  }
  #fa {
    color: green;
    margin-top: 20px;
  }
  #faq > h1 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: -20px;
  }
  #faq > .faqDiv {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }
  #faq > .faqDiv > .faqlogodiv {
    padding-top: 20px;
    width: 35%;
    height: 50%;
  }
  #faq > .faqDiv > .faqlogodiv > img {
    width: 80%;
    height: 200px;
    object-fit: contain;
  }
  #faqquestions {
    width: 45%;
    padding: 0 20px;
    margin-top: 20px;
  }
  #faqquestions .faqquestion {
    display: flex;
    justify-content: space-between;
  }
  #faqquestions .questionanswer {
    font-size: 13px;
    margin-top: 12px;
    line-height: 20px;
    color: grey;
  }
  #faqquestions .faqquestion {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    padding: 15px 20px;
    margin-bottom: 20px;
  }
  #hrline {
    margin-bottom: 30px;
  }
  
  /* ============== */
  @media only screen and (max-width: 768px) {
    #faq > h1 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    #fa {
      margin-top: 20px;
    }
    #faq > .faqDiv {
      display: flex;
      flex-direction: column;
    }
    #faq > .faqDiv > .faqlogodiv {
      width: 100%;
    }
    #faq > .faqDiv > .faqlogodiv > img {
      width: 100%;
    }
    #faqquestions {
      width: 100%;
      padding: 0;
    }
    #faqquestions .faqquestion {
      display: flex;
      align-items: center;
      padding: 10px 15px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1181px) {
    #faq > .faqDiv > .faqlogodiv > img {
      width: 50%;
      height: 200px;
      margin-left: 180px;
    }
  }
  