@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
}
body {
  font-family: "Poppins", sans-serif;
}

.main-container {
  width: 100%;
  margin: auto;
}
.aboutus-border244{
  border: 1px solid black
}
.about {
  width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 20px;
}
.about-h1 {
  text-align: center;
  font-size: 32px;
  padding:0px 9px;
  color: #05456c;
  font-weight: bold;
}
.about-ho1 {
  text-align: center;
  font-size: 32px;
  padding:0px 9px;
  color: #05456c;
  font-weight: bold;
}
.stroke {
  -webkit-text-stroke: 1px green;
  color: transparent;
}
.welcome {
  width: 90%;
  margin: auto;
  line-height: 22.5px;
}

.product-sample {
  width: 100%;
  background-color: #eaeaea;
  margin-top: 20px;
  padding: 20px 20px;
  text-align: center;
}

.product-append {
  width: 85%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  row-gap: 20px;
  column-gap: 40px;
}

.premium-service {
  width: 100%;
  text-align: center;
  padding: 30px 20px;
}

.image-append {
  width: 80%;
  margin: auto;
  display: grid;
  margin-top: 40px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  row-gap: 20px;
  column-gap: 40px;
}
.pro-con {
  height: 72%;
}
.prod {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.navbar {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
}

.navbar-second {
  display: flex;
  /* justify-content: space-around; */
}

.navbar-third {
  display: flex;
}
.navbar-third > button {
  border: 1px solid #094e1f;
  color: #094e1f;
  font-weight: bold;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #ffff;
}
.navbar-third > div {
  border-radius: 20px;
  border: 1px solid #094e1f;
  color: #094e1f;
  display: flex;
  font-weight: bold;
  padding: 5px 15px 5px 15px;
  margin-left: 20px;
}
.navbar-second > ul > li {
  margin-left: 10px;
  margin-right: 10px;
}
.cont-first-second {
  display: flex;
  margin-top: 18px;
  justify-content: space-evenly;
}
.cont-first-second > p {
  margin: 0px 10px;
  color: #094e1f;
  cursor: pointer;
}
.wel-about{
  display: flex;
  width: 40%;

  margin: auto;
  justify-content: center;
  align-items: center;
}
.welcome-image11{
  width: 41%;
  padding-top: 13px;

}
.welcome-image111{
  width: 50%;
  padding-top: 13px;
}
.welcome-image111>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.welcome-image11>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.container-second-div {
  display: flex;
  justify-content: space-evenly;
}
.container-second-div > div {
  margin: 20px 10px;
  color: #094e1f;
  cursor: pointer;
  font-size: 16px;
  font-weight: bolder;
}

.caraousel {
  width: 100%; /* Set the width of the container */
  /* height: 600px; Set a fixed height for the container (adjust as needed) */
  overflow: hidden; /* Hide any overflow outside the container */
  display: flex;
 
  margin-bottom: 50px;
}


.product-append > div > div {
  margin-bottom: 15px;
  transition: transform 0.3s ease-in-out;
}
.product-append > div > p {
  font-size: 20px;
  font-weight: 500;
  color:#05456c;
}
.product-append > div > div:hover {
  transform: scale(1.1);
}
/* .product-append>div>div>img{
    height: 200px;
    width: 200px;
} */
.product-append > div > div > img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.image-append > div > img {
  height: 100px;
  width: 120px;
}
.first-heading{
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
}
.welcome-serve{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.pre-section{
  margin-top: 30px;
}
.vertical-text1 {
  /* Optional: to make it read top to bottom */
  font-size: 30px; /* Adjust font size as needed */
}
.stroke1 {
  -webkit-text-stroke: 1px green;
  color: transparent;
}
.welcome-image{
 margin-left: 6px;
}
.welcome-image>img{
  width: 50%;
  height: 34px;
  object-fit: contain;
}
.heading-logo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 265px;
}
.heading-logo>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-hp1{
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
  color: #05456c;
  font-weight: 700;
}

.h-p1{
   margin-top: -27px;
   font-size: 25px;
   text-align: center;
   color: black;
   padding: 0px 9px;
}
/* @media (min-width: 769px) and (max-width: 1268px) {
   
  } */

/* Premium Services Updated CSS */

/* Responsive Design for Tablets */
@media (max-width:300px) and (max-width:500){
  .welcome-image{
    margin-top: -2px;
    height: 25px;
    width: 100%;
  }
  .welcome-image11 {
   width: 100%;
   padding-top: 0px;
  }
  .wel-about {
  width: 100%;
  }
  .h-p1{
    margin-top: -18px;
    font-size: 17px;
    text-align: center;
    color: black;
    padding: 0px 9px;
 }
  .about-hp1{
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #05456c;
  }
  .about-ho1 {
    text-align: center;
    font-size: 26px;
    padding:0px 9px;
    color: #05456c;
    font-weight: bold;
    margin-top: 10px;
  }
  .welcome-serve{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
   
  }
  .welcome-serve>h3{
    font-size: 22px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .welcome-image11 {
    width: 100%;
    padding-top: 7px;
    margin-left: -11px;
   }
   .wel-about {
   width: 100%;
   }

  .h-p1{
    margin-top: -18px;
    font-size: 17px;
    text-align: center;
    color: black;
    padding: 0px 9px;
 }
  .about-hp1{
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
    color: #05456c;
  }
  .about-ho1 {
    text-align: center;
    font-size: 25px;
    padding:0px 9px;
    color: #05456c;
    font-weight: bold;
    margin-top: 10px;
  }
  .welcome-serve{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
   
  }
  .welcome-image111 {
 width: 100%;
 padding-top: 6px;
  }
  .welcome-serve>h3{
    font-size: 21px;
    width: 100%;
  }
  .first-heading{
    display: flex;
    /* flex-direction: column; */
    gap: 5px;
  }
  .heading-logo {
    height: 25px;
    width: 100%;
}
  .navbar {
    background-color: white;
    display: flex;
    justify-content: space-between;
  }
  .caraousel {
    height: 300px;
    margin-bottom: 30px;
  }
  .det {
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
  .about-h1 {
    width: 100%;
    font-size: 21px;
    padding: 0px;
}

  .product-sample {
    width: 100%;
    background-color: #eaeaea;
    margin-top: 20px;
    padding: 20px 20px;
    text-align: center;
  }
  .product-append {
    width: 99%;
    margin: auto;
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    row-gap: 20px;
    column-gap: 10px;
  }
  .pro-con {
    height: 79%;
  }
  .product-append > div > div {
    width: 100%;
  }

  .product-append > div > div > img {
    height: 232px;
    width: 261px;
  }
  .second-div {
    width: 100%;
    margin: auto;
    padding: 0;
    height: 302px;
  }
}
@media (min-width: 560px) and (max-width: 850px) {
  .product-append {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  .product-append {
    width: 85%;
    margin: auto;
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    row-gap: 20px;
    column-gap: 40px;
  }
}
.carousel-container {
  width: 100vw; /* Full width of the viewport */
  overflow: hidden;
  position: relative;
}

.carouselInner {
  display: flex;
  transition: transform 1s ease-in-out;
}

.carousel-slide {
  width: 100vw; /* Each slide takes full width */
  height: 80vh; /* Adjust height as needed */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%) translateY(20px); /* Start below the view */
  opacity: 0;
  text-align: center;
  animation: slide-in 0.8s ease forwards; /* Apply slide-in animation */
}

.caption-title {
  font-size: 2rem;
  color: #064869;
  margin: 0;
}

.caption-description {
  font-size: 1.5rem;
  color: #df222d;
  margin: 5px 0;
}

.caption-subtext {
  font-size: 1.2rem;
  color: #555;
}

/* Keyframes for slide-in effect */
@keyframes slide-in {
  from {
    transform: translateX(-50%) translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
}

