.carousel-container2 {
  position: relative;
  width: 80%;
  margin: auto;
  overflow: hidden;
}

.about-h1-2 {
  text-align: center;
  margin-bottom: 20px;
}

.carousel-slider2 {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.carousel-track2 {
  display: flex;
  gap: 20px;
  animation: scrollRight 20s linear infinite;
}

.carousel-item2 {
  width: 250px;
  margin: 0 10px;
}

.carousel-image2 {
  width: 100%;
  height: 70px;
}

@keyframes scrollRight {
  0% {
      transform: translateX(-50%); /* Start slightly off-screen to the left */
  }
  100% {
      transform: translateX(0); /* Move to the right */
  }
}
